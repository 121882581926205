<template>
  <content-section :spacing="false">
    <TaskList :qso="{ append: `&operator=${id}`, prepend: '?' }" />
  </content-section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import { TaskList } from '@/composites/task'

export default {
  name: 'ViewOrganizationUserTasks',
  components: {
    ContentSection,
    TaskList,
  },
  data() {
    return {
      operatorId: null,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {},
}
</script>

<style lang="scss"></style>
